export const adminProductId = "611b8ac465064876fb35e1cf";
export const designFeatureCode = "DSF";
export const dataFeatureCode = "DDF";
export const templateFeatureCode = "DTF";

export const logLevelTypes = {
  info: 1,
  warn: 2,
  error: 3
}

export const logLevels = [
  { name: '<All>', value: '' },
  {
    name: 'Information',
    value: logLevelTypes.info
  },
  {
    name: 'Warning',
    value: logLevelTypes.warn
  },
  {
    name: 'Error',
    value: logLevelTypes.error
  }
];

export const logSources = [
  { name: '<All>', value: '' },
  { name: 'CenterPoint', value: 1 },
  { name: 'SmartForms', value: 2 },
  { name: 'DigitalSigning', value: 3 },
  { name: 'MFDX', value: 4 },
  { name: 'Viewpoint', value: 5 },
  { name: 'TextLibrary', value: 6 },
  { name: 'WebEditor', value: 7 },
  { name: 'IdentityServer', value: 8 },
  { name: 'Workflow', value: 9 },
  { name: 'MetaTool', value: 10 },
  { name: 'Archive', value: 11 },
  { name: 'PingDoxAdmin', value: 12 },
  { name: 'Support', value: 13 },
  { name: 'Metaforce Solution', value: 14 },
  // { name: 'Custom', value: 15 },
  { name: 'Interact Admin', value: 16 },
];